<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card color="secondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Editar Perfil</div>

            <div class="subtitle-1 font-weight-light">Completar seu perfil</div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    class="purple-input"
                    label="Nome Completo (Nome usado no certificado)*"
                    v-model="user.displayName"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    disabled
                    label="Email"
                    class="purple-input"
                    v-model="user.email"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" name="Telefone">
                    <v-text-field
                      v-model="user.phoneNumber"
                      :counter="14"
                      :error-messages="errors"
                      label="Telefone"
                      required
                      type="tel"
                      placeholder="+5585999999999"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="CPF/CNPJ"
                    type="number"
                    class="purple-input"
                    v-model="user.cpfcnpj"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    disabled
                    label="uid"
                    class="purple-input"
                    v-model="user.uid"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click.native="atualizar()"
                  >
                    Atualizar Perfil
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card
          class="v-card-profile"
          :avatar="url ? url : $store.state.user.photoURL || avatar"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ $store.state.user.displayName }}
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ $store.state.user.email }}
            </h4>
            <!-- <p>
              {{user.photoURL}}
            </p> -->
            <v-file-input
              ref="foto"
              class="d-none"
              accept="image/png, image/jpeg, image/bmp"
              type="file"
              @change="onFileChanged"
            ></v-file-input>
            <v-btn
              @click.native="uploadImagem"
              color="success"
              rounded
              class="mr-0"
            >
              {{ $store.state.user.photoURL ? "Mudar Foto" : "Inserir Foto" }}
            </v-btn>
            <v-row align="center">
              <v-checkbox
                v-model="mudarSenha"
                hide-details
                class="shrink mr-2 mt-0"
                @click.native="$refs.novaSenha.focus()"
              ></v-checkbox>
              <v-text-field
                ref="novaSenha"
                :disabled="!mudarSenha"
                label="Nova Senha"
                type="password"
                v-model="novaSenha"
              ></v-text-field>
            </v-row>
            <v-btn
              :disabled="!mudarSenha"
              @click.native="updatePassword()"
              color="success"
              rounded
              class="mr-0"
            >
              Mudar Senha
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import toastr from "toastr";
import firebase from "firebase/app";
import firebasestorage from "firebase/storage";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
// Utilities
import { mapState, mapMutations } from "vuex";
import db from "firebase/database";
setInteractionMode("eager");
extend("digits", {
  ...digits,
  message: "{_field_} precisa ter {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio.",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} não está no padrão correto: {regex}",
});

extend("email", {
  ...email,
  message: "Email precisa ser válido",
});

export default {
  data: () => ({
    selectedFile: null,
    url: null,
    mudarSenha: false,
    novaSenha: null,
  }),
  methods: {
    ...mapMutations({
      setUser: "SET_USER",
    }),
    updatePassword() {
      const user = firebase.auth().currentUser;
      user
        .updatePassword(this.novaSenha)
        .then(()=> {
          // Update successful.
          toastr.success("Senha modificada com sucesso!", "Confabular");
          this.mudarSenha = false
        })
        .catch((error)=> {
          // An error happened.
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    setFormatedUser(payload) {
      let user = {};
      user.phoneNumber = payload.phoneNumber || "";
      user.cpfcnpj = payload.cpfcnpj || "";
      user.photoURL = payload.photoURL || "";
      user.uid = payload.uid || "";
      user.displayName = payload.displayName || "";
      user.email = payload.email || "";
      user.isAdmin =payload.isAdmin
      user.franquia =payload.franquia || []
      this.setUser(user);
    },
    onFileChanged(file) {
      this.selectedFile = file;
      this.url = URL.createObjectURL(file);
      this.onUpload();
    },
    getCurrentUser() {
      let user = firebase.auth().currentUser;
      this.setFormatedUser(user);
      firebase
        .database()
        .ref("/users/" + this.user.uid)
        .once("value")
        .then((snapshot) => {
          if (snapshot.val()) {
            this.user.phoneNumber = snapshot.val().phoneNumber || "";
            this.user.cpfcnpj = snapshot.val().cpfcnpj || "";
            this.user.photoURL = snapshot.val().photoURL || "";
            this.user.displayName = snapshot.val().displayName || "";
            this.user.email = snapshot.val().email || "";
            this.user.isAdmin = snapshot.val().isAdmin;
            this.user.franquia = snapshot.val().franquia || [];
            // console.log(this.user)
          }
        });
      return user;
    },
    onUpload() {
      const fileName = this.selectedFile.name;
      const storageRef = firebase
        .storage()
        .ref(fileName)
        .put(this.selectedFile);
      // Upload photoURL.
      toastr.info("Realizando o upload da imagem!", "Confabular");
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              //delete this.user.photoURL
              toastr.success("Upload da imagem realizado!", "Confabular");
              this.user.photoURL = url;
              this.atualizar();
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        }
      );
    },
    uploadImagem() {
      //this.$refs.foto.click()
      // console.log(this)
      this.$refs.foto.$refs.input.click();
    },
    atualizar() {
      let currentUser = firebase.auth().currentUser;
      let userData = {
        displayName: this.user.displayName || "",
        phoneNumber: this.user.phoneNumber || "",
        cpfcnpj: this.user.cpfcnpj || "",
        photoURL: this.user.photoURL || "",
        email: this.user.email || "",
        isAdmin: this.user.isAdmin,
        uid: this.user.uid
      };
      currentUser
        .updateProfile(userData)
        .then((userRecord) => {
          firebase
            .database()
            .ref("users/" + this.user.uid)
            .update(userData)
            .then(() => {
              // Update successful.
              toastr.success("Perfil Atualizado com sucesso!", "Confabular");
              this.getCurrentUser();
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        })
        .catch(function (error) {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
  },
  created() {
    this.getCurrentUser();
  },
  updated() {
    // console.log(this.user)
  },
  computed: {
    ...mapState(["avatar"]),
    user: {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        // console.log(val)
        this.$store.commit("SET_USER", val);
      },
    },
  },
};
</script>
